import React from "react";

import OrderContext from "./context";
import {Order} from "./types";

export function useOrders(): Order[] {
  const {
    state: {orders},
  } = React.useContext(OrderContext);

  return orders;
}
