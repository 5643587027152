import React from "react";

import {Tenant, Context, State} from "./types";

interface Props {
  initialValue: Tenant;
  children: React.ReactElement;
}

const TenantContext = React.createContext({} as Context);

const TenantProvider: React.FC<Props> = ({children, initialValue: tenant}) => {
  const state: State = {tenant};

  return <TenantContext.Provider value={{state}}>{children}</TenantContext.Provider>;
};

export {TenantProvider as Provider, TenantContext as default};
