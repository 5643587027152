import React from "react";
import {
  Box,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Flex,
  Stack,
  Image,
  Button,
  Text,
} from "@chakra-ui/core";
import CheckIcon from "@pency/components/icons/Check";
import Modal from "@pency/components/structure/Modal";
import LogOutIcon from "@pency/components/icons/LogOut";
import IconButton from "@pency/components/controls/IconButton";
import CompassIcon from "@pency/components/icons/Compass";
import CogIcon from "@pency/components/icons/Cog";
import Content from "@pency/components/structure/Content";

import {useOrders} from "../hooks";
import {Order} from "../types";
import OrdersList from "../components/OrdersList";
import OrderDetails from "../components/OrderDetails";

import ConfigurationScreen from "./Configuration";

import {useSession} from "~/session/hooks";
import {useTranslation} from "~/i18n/hooks";

const OrdersScreen: React.FC = () => {
  const {signOut} = useSession();
  const t = useTranslation();
  const canPrint = Boolean(window["printService"]);
  const orders = useOrders();
  const [selected, setSelected] = React.useState<Order | null>(null);
  const [printed, setPrinted] = React.useState<Order[]>([]);
  const pending = React.useMemo(() => {
    const ids = printed.map(({id}) => id);

    return orders.filter(({id}) => !ids.includes(id));
  }, [orders, printed]);

  function handlePrint(ticket: Order) {
    window?.printService?.print(ticket);

    setPrinted((printed) => printed.filter((_ticket) => _ticket.id !== ticket.id).concat(ticket));
  }

  return (
    <Box as="main" backgroundColor="white" height="100%" overflowY="auto">
      <Flex alignItems="center" boxShadow="sm" height={16} paddingY={2} position="relative">
        <Content paddingX={4}>
          <Flex alignItems="center" justifyContent="space-between" width="100%">
            <Image alt="Pency" src="/logo.svg" />
            <IconButton
              isCollapsable
              color="black"
              fontWeight="500"
              leftIcon={LogOutIcon}
              variant="link"
              onClick={signOut}
            >
              {t("common.exit")}
            </IconButton>
          </Flex>
        </Content>
      </Flex>
      <Tabs size="lg" variantColor="primary">
        <Stack
          isInline
          alignItems="center"
          backgroundColor="gray.100"
          borderBottom="1px solid"
          borderColor="gray.200"
          height="100%"
          spacing={4}
        >
          <Content paddingX={4}>
            <Box height="100%" width="100%">
              <TabList border="none" height={16} overflowX="auto" overflowY="hidden">
                <Tab fontSize="md" fontWeight={500}>
                  <CompassIcon marginRight={2} />
                  <Text>{t("orders.pending")}</Text>
                </Tab>
                <Tab display={canPrint ? "inherit" : "none"} fontSize="md" fontWeight={500}>
                  <CheckIcon marginRight={2} />
                  <Text>{t("orders.printed")}</Text>
                </Tab>
                <Tab display={canPrint ? "inherit" : "none"} fontSize="md" fontWeight={500}>
                  <CogIcon marginRight={2} />
                  <Text>{t("common.configuration")}</Text>
                </Tab>
              </TabList>
            </Box>
          </Content>
        </Stack>
        <TabPanels flex={1}>
          <TabPanel>
            <Content padding={4}>
              <Stack flex={1} overflowY="auto" spacing={4}>
                {pending.length ? (
                  <OrdersList data={pending} onClick={setSelected} />
                ) : (
                  <Text fontSize="xl" margin="auto" opacity={0.5}>
                    No hay tickets pendientes
                  </Text>
                )}
              </Stack>
            </Content>
          </TabPanel>
          <TabPanel>
            <Content padding={4}>
              <Stack flex={1} overflowY="auto" spacing={4}>
                {Boolean(printed.length) ? (
                  <OrdersList data={printed} onClick={setSelected} />
                ) : (
                  <Text fontSize="xl" margin="auto" opacity={0.5}>
                    No hay tickets impresos
                  </Text>
                )}
              </Stack>
            </Content>
          </TabPanel>
          <TabPanel>
            <ConfigurationScreen />
          </TabPanel>
        </TabPanels>
        {selected && (
          <Modal isOpen size="lg" onClose={() => setSelected(null)}>
            <Stack flex={2} padding={4} spacing={3}>
              <OrderDetails data={selected} />
              {canPrint && (
                <Button variantColor="primary" onClick={() => handlePrint(selected)}>
                  Imprimir
                </Button>
              )}
              <Button variant="ghost" onClick={() => setSelected(null)}>
                Cerrar
              </Button>
            </Stack>
          </Modal>
        )}
      </Tabs>
    </Box>
  );
};

export default OrdersScreen;
