import React from "react";

import {Order} from "./types";
import api from "./api";

import {useTenant} from "~/tenant/hooks";

export interface Context {
  state: {
    orders: Order[];
  };
}

const OrderContext = React.createContext({} as Context);

const OrderProvider: React.FC = ({children}) => {
  const tenant = useTenant();
  const [orders, setOrders] = React.useState<Order[]>([]);

  React.useEffect(() => {
    const channel = api.subscribe(tenant.slug);

    api.listen("order", (order: Omit<Order, "created" | "printed">) => {
      return setOrders((orders) =>
        orders.concat({
          ...order,
          created: +new Date(),
          printed: null,
        }),
      );
    });

    return () => {
      channel.disconnect();
    };
  }, [tenant.slug]);

  const state: Context["state"] = {orders};

  return <OrderContext.Provider value={{state}}>{children}</OrderContext.Provider>;
};

export {OrderProvider as Provider, OrderContext as default};
