import {ParsedUrlQuery} from "querystring";

import React from "react";
import {GetServerSideProps} from "next";
import Head from "next/head";
import {Global, css} from "@emotion/core";
import {DEFAULT_LOCALE, LOCALES} from "@pency/constants/catalogs/i18n";
import {serverSideTranslations} from "next-i18next/serverSideTranslations";

import api from "~/tenant/api";
import {META} from "~/app/constants/config";
import {Provider as SessionProvider} from "~/session/context";
import {Tenant} from "~/tenant/types";
import OrdersScreen from "~/order/screens/Orders";
import {Provider as TenantProvider} from "~/tenant/context";
import {Provider as OrderProvider} from "~/order/context";

interface Props {
  tenant: Tenant;
}

interface Params extends ParsedUrlQuery {
  slug: Tenant["slug"];
}

const SlugRoute: React.FC<Props> = ({tenant}) => {
  return (
    <>
      <Global
        styles={css`
          html,
          body,
          #__next {
            height: 100%;
          }

          * {
            letter-spacing: -0.2px;
            touch-action: manipulation;
            font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
              "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
          }
        `}
      />
      <Head>
        <link href={META.favicon} rel="icon" />
        <link href={META.appleicon} rel="apple-touch-icon" />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@300;500;700&display=swap"
          rel="stylesheet"
        />
        <title>{`Panel de ordenes | ${META.title}`}</title>
        <meta content={META.theme} name="theme-color" />
        <meta content={META.description} name="description" />
        <meta content={META.keywords} name="keywords" />
        <meta content={META.author} name="author" />
        <meta content={META.fbapp} property="fb:app_id" />
        <meta content="summary_large_image" name="twitter:card" />
        <meta content={META.twitter} name="twitter:site" />
        <meta content={META.twitter} name="twitter:creator" />
        <meta content={META.url} property="og:url" />
        <meta content="website" property="og:type" />
        <meta content={META.title} property="og:title" />
        <meta content={META.description} property="og:description" />
        <meta content={META.banner?.url} property="og:image" />
        <meta content={META.banner?.url} property="og:image:secure" />
        <meta content={META.banner?.url} property="og:image:url" />
        <meta content={META.banner?.format} property="og:image:type" />
        <meta content={META.banner?.width} property="og:image:width" />
        <meta content={META.banner?.height} property="og:image:height" />
        <meta content={META.title} property="og:image:alt" />
        <meta content="noindex, nofollow" name="robots" />
      </Head>
      <TenantProvider initialValue={tenant}>
        <SessionProvider>
          <OrderProvider>
            <OrdersScreen />
          </OrderProvider>
        </SessionProvider>
      </TenantProvider>
    </>
  );
};

export const getServerSideProps: GetServerSideProps<any, Params> = async function ({
  params: {slug},
  res,
}) {
  try {
    // Get the tenant for this page slug
    const tenant = await api.fetch({slug});
    const storeLocale = LOCALES[tenant.country];
    const availableLocales = Object.values(LOCALES);
    const currentLocale = availableLocales.includes(storeLocale) ? storeLocale : DEFAULT_LOCALE;

    if (!tenant.flags.includes("orders")) {
      return {
        props: {
          ...(await serverSideTranslations(DEFAULT_LOCALE)),
          statusCode: 401,
        },
      };
    }

    // Return props
    return {
      props: {
        ...(await serverSideTranslations(currentLocale)),
        tenant,
      },
    };
  } catch (err) {
    // If something failed report it to _app.tsx
    return {
      props: {
        ...(await serverSideTranslations(DEFAULT_LOCALE)),
        statusCode: err?.status || res?.statusCode || 404,
      },
    };
  }
};

export default SlugRoute;
