import React from "react";
import {Select} from "@chakra-ui/core";
import Content from "@pency/components/structure/Content";
import FormControl from "@pency/components/form/FormControl";

const ConfigurationScreen: React.FC = () => {
  const canPrint = process.browser && Boolean(window.printService);
  const [printer, setPrinter] = React.useState<string>(window.printService?.selected || "");

  React.useEffect(() => {
    if (canPrint) {
      window.printService.selected = printer;
    }
  }, [printer, canPrint]);

  return (
    <Content padding={4}>
      <FormControl
        error={!canPrint && "Tu dispositivo no puede imprimir"}
        isInvalid={!canPrint}
        label="Impresora"
        name="images"
      >
        <Select
          value={printer}
          variant="filled"
          onChange={(event) => setPrinter(event.target.value)}
        >
          <option disabled value="">
            Selecciona una impresora
          </option>
          {window.printService?.list().map((printer) => (
            <option key={printer.name} value={printer.name}>
              {printer.name}
            </option>
          ))}
        </Select>
      </FormControl>
    </Content>
  );
};

export default ConfigurationScreen;
