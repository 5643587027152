import React from "react";
import {Text, BoxProps, Stack} from "@chakra-ui/core";

import type {Order} from "../types";

import {useDate} from "~/i18n/hooks";

interface Props extends Omit<BoxProps, "onClick"> {
  data: Order[];
  onClick?: (ticket: Order) => void;
}

const OrdersList: React.FC<Props> = ({onClick, data, ...props}) => {
  const d = useDate();

  return (
    <Stack>
      {data.map((ticket) => (
        <Stack
          key={ticket.id}
          backgroundColor="white"
          borderColor="gray.100"
          borderRadius="md"
          borderWidth={1}
          boxShadow="sm"
          color="blackAlpha.900"
          cursor={onClick ? "pointer" : "inherit"}
          padding={2}
          spacing={1}
          whiteSpace="pre"
          onClick={() => onClick && onClick(ticket)}
          {...props}
        >
          <Text>{ticket.id}</Text>
          <Text fontSize="sm" opacity={0.5}>
            Creado: {d(ticket.created)}
          </Text>
          {ticket.printed && <Text>Impreso: {d(ticket.printed)}</Text>}
        </Stack>
      ))}
    </Stack>
  );
};

export default OrdersList;
