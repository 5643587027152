import React from "react";
import {Text, BoxProps} from "@chakra-ui/core";

import type {Order} from "../types";

interface Props extends BoxProps {
  data: Order;
}

const OrderDetails: React.FC<Props> = ({data, ...props}) => {
  return (
    <Text
      backgroundColor="white"
      borderRadius="md"
      boxShadow="sm"
      color="blackAlpha.900"
      fontSize="sm"
      padding={4}
      whiteSpace="pre-wrap"
      {...props}
    >
      {data.message}
    </Text>
  );
};

export default OrderDetails;
